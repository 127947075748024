import {StoryblokComponent, storyblokEditable} from "@storyblok/react";
import React, {memo} from "react";

const component = React.memo(function Relationship({blok}: {blok: any}) {
    if(!blok.story || !blok.story.content) {
        return <span>Please reload the page</span>;
    }
    let isPreview = false;
    if (typeof window !== 'undefined') {
        isPreview = window.location.href.includes('preview');
    }

    let pe = {};
    // if env dev
    if (process.env.NODE_ENV === 'development') {
        pe = {'pointerEvents':'none'};
    }

    return <div {...storyblokEditable(blok)}>
        <div style={pe}><StoryblokComponent
            story={blok.story} blok={blok.story.content}/></div></div>
})

export default component